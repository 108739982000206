import PropTypes from "prop-types"
import * as React from "react"
import { useState } from "react"
import "../styles/layout.css"
import Footer from "./Footer"
import Header from "./Header"
import NavigationMenu from "./NavigationMenu"
import SearchPopup from "./SearchPopup"
import LoginPopup from "./LoginPopup"

import LoggedOutInstructionPage from "./LoggedOutInstructionPage"
import { checkIsAuthenticated } from "../services/SessionUtils"

const Layout = ({ children }) => {
  const [isShowResponsive, setIsShowResponsive] = useState(false)
  const [isSearchWrapperClick, setIsSearchWrapperClick] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState("")

  React.useEffect(() => {
    checkIsAuthenticated(authenticated => {
      setIsAuthenticated(authenticated)
      setLoading(false)
    })
  }, [])

  return (
    <>
      <Header
        isShowResponsive={isShowResponsive}
        setIsShowResponsive={setIsShowResponsive}
        setIsSearchWrapperClick={setIsSearchWrapperClick}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setIsAuthenticated={setIsAuthenticated}
      />

      {isSearchWrapperClick && (
        <SearchPopup
          setIsSearchWrapperClick={setIsSearchWrapperClick}
          setIsShowResponsive={setIsShowResponsive}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      )}
      {!isAuthenticated && !isLoading && (
        <LoginPopup setIsAuthenticated={setIsAuthenticated} />
      )}
      <div
        className={`content-wrapper${isShowResponsive ? " hide-scroll" : ""}`}
      >
        <div className="container">
          <div className="d-flex content-layout">
            {isAuthenticated && !isLoading && (
              <NavigationMenu
                isShowResponsive={isShowResponsive}
                setIsShowResponsive={setIsShowResponsive}
              />
            )}
            {isAuthenticated && !isLoading && (
              <main className="content-body">{children}</main>
            )}

            {!isAuthenticated && !isLoading && <LoggedOutInstructionPage />}
            {isLoading && <main className="content-body">Loading...</main>}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
