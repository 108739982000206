import { useLocation } from "@reach/router"
import React, { useState } from "react"

const CollapsibleSection = ({ title, children, titleClass }) => {
  const [expanded, setExpanded] = useState(false)
  const location = useLocation()
  const pathnameArray = location.pathname.toUpperCase().split("/")

  return (
    <>
      {titleClass === "menu-heading" && (
        <div
          className={`${titleClass} ${
            expanded
              ? "active"
              : pathnameArray.indexOf(
                  title.toUpperCase().replaceAll(" ", "-")
                ) !== -1
              ? "active"
              : ""
          }`}
          onClick={() => {
            const newState = !expanded
            setExpanded(newState)
          }}
        >
          <span>{title}</span>
        </div>
      )}
      {(titleClass === "menu-sub-heading" ||
        titleClass === "menu-sub-sub-heading") && (
        <div
          className={`${titleClass} ${
            expanded
              ? "active"
              : pathnameArray.indexOf(
                  title.toUpperCase().replaceAll(" ", "-")
                ) !== -1
              ? "active"
              : ""
          }`}
          onClick={() => {
            const newState = !expanded
            setExpanded(newState)
          }}
        >
          <h4 className="title">{title}</h4>
        </div>
      )}
      <div
        style={{
          display: expanded
            ? "block"
            : pathnameArray.indexOf(
                title.toUpperCase().replaceAll(" ", "-")
              ) !== -1
            ? "block"
            : "none",
        }}
      >
        {children}
      </div>
    </>
  )
}

export default CollapsibleSection
