import React from "react"
import { graphql, StaticQuery } from "gatsby"

const LoggedOutInstructionPage = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            NoLoginText
          }
        }
      }
    `}
    render={data => {
      // console.log("NoLogin", data)
      return (
        <main className="content-body">
          {/* {data?.site?.siteMetadata?.NoLoginText} */}
          <h1>Welcome to the NuFlights developer portal!</h1>
          <br />
          Login with an active use credential in your NuFlights Account.
          <br />
          <br />
          To create your NuFlights account, contact us at{" "}
          <b>www.nuflights.com/#contact</b>.
        </main>
      )
    }}
  ></StaticQuery>
)

export default LoggedOutInstructionPage
