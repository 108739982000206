import React, { useState } from "react"
import { login } from "../services/SessionUtils"

const LoginPopup = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [pageLoading, setIsPageLoading] = useState(false)
  const [pageError, setPageError] = useState("")
  const DEFAULT_ERROR_MESSAGE =
    "Something went wrong, please try after sometime"

  function onLoginClick() {
    setIsPageLoading(true)
    setPageError("")
    login(
      () => {
        setIsPageLoading(false)
        setIsAuthenticated(true)
      },
      error => {
        if (
          error &&
          error.graphQLErrors &&
          error.graphQLErrors.length > 0 &&
          error.graphQLErrors[0].message
        ) {
          setPageError(error?.graphQLErrors[0]?.message)
        } else {
          setPageError(DEFAULT_ERROR_MESSAGE)
        }
        setIsPageLoading(false)
      },
      username,
      password
    )
  }

  function validateForm() {
    if (
      username == "" ||
      password == "" ||
      !username.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return false
    }
    return true
  }
  return (
    <div className="login-popup-background">
      <div className="login-popup">
        <div>
          <span>Your NuFlights Login</span>
          <input
            type="text"
            placeholder="Email address"
            value={username}
            className="login"
            onChange={event => {
              setUsername(event.target.value)
            }}
            onKeyPress={event => {
              if (event.key === "Enter" && validateForm()) {
                onLoginClick()
              }
            }}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            className="password"
            onChange={event => {
              setPassword(event.target.value)
            }}
            onKeyPress={event => {
              if (event.key === "Enter" && validateForm()) {
                onLoginClick()
              }
            }}
          />
          <input
            type="button"
            value="Login"
            className="loginButton"
            onClick={() => {
              onLoginClick()
            }}
            disabled={!validateForm()}
          />
        </div>

        {pageLoading && <p>Loading...</p>}
        {pageError && (
          <p style={{ color: "red", fontStyle: "italic" }}>{pageError}</p>
        )}
      </div>
    </div>
  )
}
export default LoginPopup
