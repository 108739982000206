import * as React from "react"
import { Link } from "gatsby"
const Footer = () => (
  <footer className="footer">
    <div className="container d-flex justify-content-between align-items-center">
      <p className="copyright">
        {" "}
        Copyright &copy; 2021-2022 NuCore Solutions. All Rights Reserved
      </p>
      <div className="footer-link">
        {footerSubMenu.map(function (link, index) {
          return (
            <Link key={index} to={link.id}>
              {link.description}
            </Link>
          )
        })}
      </div>
    </div>
  </footer>
)
const footerSubMenu = [
  // {
  //   id: "/",
  //   description: "Change Log",
  // },
  // {
  //   id: "/",
  //   description: "FAQ",
  // },
  {
    id: "/api-explorer/",
    description: "API Explorer",
  },
]

export default Footer
