import { gql } from "@apollo/client"
import { apolloClient } from "./ApolloClient"
import * as jose from "jose"
const LOCALSTORAGE_TOKEN = "token"
const LOCALSTORAGE_REFRESH_TOKEN = "refreshToken"
// const LOCALSTORAGE_REFRESH_EXPIRE = "refreshExpiresIn"
const LOCALSTORAGE_PUBLIC_KEY = "jwtPublicKey"
const REFRESH_TOKEN_MUTATION = gql`
  mutation authRefreshMutation($input: RefreshInput!) {
    refreshToken(input: $input) {
      token
      payload
      refreshToken
    }
  }
`
const LOGIN_MUTATION = gql`
  mutation LoginMutation($rq: LoginInput!) {
    login(input: $rq) {
      token
      refreshToken
      refreshExpiresIn
      user {
        id
        username
        firstName
        lastName
      }
    }
  }
`
const GET_PUBLIC_KEY = gql`
  query fetchPublicKeyQuery {
    jwtPublicKey
  }
`
export function clearSessionFromLocalStorage() {
  // localStorage.removeItem(LOCALSTORAGE_REFRESH_EXPIRE)
  localStorage.removeItem(LOCALSTORAGE_REFRESH_TOKEN)
  localStorage.removeItem(LOCALSTORAGE_TOKEN)
}

export function setSessionInLocalStorage(token, refreshToken) {
  // localStorage.setItem(LOCALSTORAGE_REFRESH_EXPIRE, refreshExpire)
  localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN, refreshToken)
  localStorage.setItem(LOCALSTORAGE_TOKEN, token)
}

export function canRefreshToken() {
  if (localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN)) {
    return true
  } else {
    return false
  }
}

export function checkIsAuthenticated(onComplete: (boolean) => false) {
  let isAuthenticated = false
  let token = localStorage.getItem(LOCALSTORAGE_TOKEN)
  if (token) {
    try {
      const payloadBase64 = token?.split(".")[1]
      const decodedJson = atob(payloadBase64)
      const exp = JSON.parse(decodedJson)?.exp
      const isExpired = Date.now() - exp * 1000
      if (isExpired > 0) {
        isAuthenticated = false
      } else {
        onComplete(true)
        isAuthenticated = true
      }
    } catch (e) {
      clearSessionFromLocalStorage()
      onComplete(false)
      isAuthenticated = false
    }
  }
  if (!isAuthenticated) {
    // Attempt refreshing if not autheticated
    if (canRefreshToken()) {
      refreshToken(
        () => {
          onComplete(true)
        },
        error => {
          onComplete(false)
        }
      )
    } else {
      onComplete(false)
    }
  }
}

export function refreshToken(onComplete: () => void, onError: (error) => void) {
  apolloClient
    .mutate({
      mutation: REFRESH_TOKEN_MUTATION,
      variables: {
        input: {
          refreshToken: localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN),
        },
      },
    })
    .then(data => {
      setSessionInLocalStorage(
        data?.data?.refreshToken?.token,
        data?.data?.refreshToken?.refreshToken
      )

      verifySignature(onComplete, onError)
    })
    .catch(error => {
      console.log("refresh token error", error)
      onError(error)
    })
}

export function login(
  onComplete: () => void,
  onError: (error) => void,
  username,
  password
) {
  apolloClient
    .mutate({
      mutation: LOGIN_MUTATION,
      variables: {
        rq: {
          username: username,
          password: password,
        },
      },
    })
    .then(data => {
      setSessionInLocalStorage(
        data?.data?.login?.token,
        data?.data?.login?.refreshToken
      )
      verifySignature(onComplete, onError)
    })
    .catch(error => {
      console.log("Login error", error)
      onError(error)
    })
}

function getPublicKey(success, fail) {
  apolloClient
    .query({
      query: GET_PUBLIC_KEY,
    })
    .then(data => {
      localStorage.setItem(LOCALSTORAGE_PUBLIC_KEY, data?.data?.jwtPublicKey)
      success()
    })
    .catch(error => {
      localStorage.removeItem(LOCALSTORAGE_PUBLIC_KEY)
      fail(error)
    })
}

function verifySignature(onComplete, onError) {
  getPublicKey(
    async () => {
      await jose
        .importSPKI(localStorage.getItem(LOCALSTORAGE_PUBLIC_KEY), "RS256")
        .then(res => {
          jose
            .jwtVerify(localStorage.getItem(LOCALSTORAGE_TOKEN), res)
            .then(result => {
              console.log("jwtVerify", result)
              onComplete(result)
            })
            .catch(error => {
              console.log("jwtVerify error", error, JSON.stringify(error))
              onError(error)
            })
        })
        .catch(error => {
          clearSessionFromLocalStorage()
          onError(error)
          console.log("error", error, JSON.stringify(error))
        })
    },
    error => {
      console.log("getPublicKey", error)
    }
  )
}
