import { ApolloClient, InMemoryCache } from '@apollo/client';

// require("dotenv").config({
//     path: `.env.${process.env.NODE_ENV}`,
//   })

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  // Provide required constructor fields
  cache: cache,
  uri: process.env.API_URL,

  // Provide some optional constructor fields
//   name: 'react-web-client',
//   version: '1.3',
//   queryDeduplication: false,
//   defaultOptions: {
//     watchQuery: {
//       fetchPolicy: 'cache-and-network',
//     },
//   },
});