import * as React from "react"
import logo from "../images/nuFlightsLogo.png"
import menuIcon from "../images/menu-icon.png"
import closeIcon from "../images/close-icon.png"
import { Link } from "gatsby"
import { useState } from "react"
import { clearSessionFromLocalStorage } from "../services/SessionUtils"

const Header = ({
  isShowResponsive,
  setIsShowResponsive,
  setIsSearchWrapperClick,
  searchValue,
  setSearchValue,
  setIsAuthenticated,
}) => {
  const [isSearchFocus, setIsSearchFocus] = useState(false)

  const onMenuClick = () => {
    setIsShowResponsive(true)
  }
  const onCloseClick = () => {
    setIsShowResponsive(false)
  }
  const onSearchWrapperClick = () => {
    if (window?.innerWidth <= 550) {
      setIsSearchFocus(true)
    } else {
      setIsSearchFocus(false)
    }
    // console.log(window.innerWidth + " - " + window.outerWidth)
  }
  const onSearchFocusClose = () => {
    setIsSearchFocus(false)
  }
  return (
    <header className="header">
      <div className="container d-flex justify-content-between align-items-center">
        <Link to="/">
          <img src={logo} className="logo" alt="nuflight-logo" />
        </Link>
        <div className="d-flex align-items-center">
          <div
            style={{
              marginTop: "11px",
              marginBottom: "0px",
              marginLeft: "30px",
              fontSize: "20px",
              color: "#11141f",
            }}
          >
            for developers
          </div>
          <h6
            style={{
              color: "white",
              backgroundColor: "#ff5e00",
              borderRadius: "6px",
              fontWeight: "normal",
              padding: "0px 5px",
              fontSize: "12px",
            }}
          >
            NDC 21.3
          </h6>
        </div>
        <div
          className={`actions d-flex align-items-center${
            isShowResponsive ? " show" : ""
          }`}
        >
          <div
            className={`search-wrapper${isSearchFocus ? " focus" : ""}`}
            onClick={() => {
              onSearchWrapperClick()
            }}
          >
            <input
              type="text"
              placeholder="Search, APIs and Others"
              className="search"
              value={searchValue}
              onChange={event => {
                setSearchValue(event.target.value)
              }}
              onKeyPress={event => {
                event.stopPropagation()
                if (event.key === "Enter") {
                  // globalSearch(event.target.value)
                  setIsShowResponsive(true)
                  setIsSearchWrapperClick(true)
                }
              }}
            />
            <span
              className="close"
              onClick={event => {
                event.stopPropagation()
                onSearchFocusClose()
              }}
            >
              ✖
            </span>
          </div>
          <input
            type="button"
            value="Logout"
            className="logout"
            onClick={() => {
              clearSessionFromLocalStorage()
              setIsAuthenticated(false)
            }}
          />
          {/* <div className="right-side">
            <Link to="https://www.nuflights.com/" className="link">
              Your NuFlights Account
            </Link>
          </div> */}
          <div className="close-icon-wrapper">
            <img
              src={closeIcon}
              className="close-icon"
              alt="close-icon"
              onClick={() => {
                onCloseClick()
              }}
            />
          </div>
        </div>
        <div className="menu-icon-wrapper">
          <img
            src={menuIcon}
            className="menu-icon"
            alt="menu-icon"
            onClick={() => {
              onMenuClick()
            }}
          />
        </div>
      </div>
    </header>
  )
}

export default Header
