import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

const SearchPopup = ({
  setIsSearchWrapperClick,
  setIsShowResponsive,
  searchValue,
  setSearchValue,
}) => {
  const [searchResults, setSearchResults] = useState([])
  const globalSearch = searchText => {
    setSearchValue(searchText)
    // search individual words
    var tempResult = fullTextSearch(searchValue.replaceAll(" ", ""))
    searchValue.split(" ")?.map(text => {
      tempResult = tempResult.concat(fullTextSearch(text))
    })
    setSearchResults(Array.from(new Set(tempResult)))
    console.log("fullTextSearch", searchResults)
  }
  const fullTextSearch = query => {
    var index = window?.__FLEXSEARCH__?.en.index
    var store = window?.__FLEXSEARCH__?.en.store
    // console.log("index: ", index)
    // console.log("store: ", store)
    if (!query || !index) {
      return []
    } else {
      let results = []
      Object.keys(index).forEach(idx => {
        results.push(...index[idx].values.search(query))
      })

      results = Array.from(new Set(results))

      const nodes = store
        .filter(node => (results.includes(node.id) ? node : null))
        .map(node => node.node)

      return nodes
    }
  }

  useEffect(() => {
    globalSearch(searchValue)
  }, [searchValue])

  return (
    <>
      <div
        className="search-popup-background"
        onClick={() => {
          setIsSearchWrapperClick(false)
          setIsShowResponsive(false)
          setSearchValue("")
        }}
      ></div>
      <div className="search-popup">
        <div className="search-box">
          <input
            type="text"
            placeholder="Search, APIs and Others"
            className="search"
            value={searchValue}
            onChange={event => {
              setSearchValue(event.target.value)
            }}
            onKeyPress={event => {
              event.stopPropagation()
              if (event.key === "Enter") {
                globalSearch(event.target.value)
              }
            }}
          />
        </div>

        <ul className="search-list">
          {searchResults &&
            searchResults?.map(result => (
              <Link to={`/${result?.sourceInstanceName}/${result?.slug}`}>
                <li className="search-list-item">
                  <div className="search-card">
                    <p className="title">{result?.title}</p>
                    <p className="content">{result?.excerpt}</p>
                    <p className="page-info">{result?.sourceInstanceName}</p>
                  </div>
                </li>
              </Link>
            ))}
        </ul>
      </div>
    </>
  )
}
export default SearchPopup
