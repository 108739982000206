// If you don't want to use TypeScript you can delete this file!
import { useLocation } from "@reach/router"
import { graphql, Link, StaticQuery } from "gatsby"
import * as React from "react"
import CollapsibleSection from "./CollapsibleSection"
import PageMenu from "./PageMenu"

const NavigationMenu = ({ isShowResponsive, setIsShowResponsive }) => (
  <StaticQuery
    query={graphql`
      query {
        tutorials: allFile(
          filter: {
            sourceInstanceName: { eq: "tutorials" }
            relativePath: { ne: "index.mdx" }
          }
          sort: { order: ASC, fields: childrenMdx___frontmatter___order }
        ) {
          nodes {
            childMdx {
              frontmatter {
                title
                date
              }
              slug
            }
          }
        }
        concepts: allFile(
          filter: {
            sourceInstanceName: { eq: "concepts" }
            relativePath: { ne: "index.mdx" }
          }
          sort: { order: ASC, fields: childrenMdx___frontmatter___order }
        ) {
          nodes {
            childMdx {
              frontmatter {
                title
                date
              }
              slug
            }
          }
        }
        ndcUsages: allFile(
          filter: {
            sourceInstanceName: { eq: "references/ndc-apis/usages" }
            relativePath: { ne: "index.mdx" }
            name: { eq: "index" }
          }
          sort: { order: ASC, fields: childrenMdx___frontmatter___order }
        ) {
          nodes {
            childMdx {
              frontmatter {
                title
                date
              }
              slug
            }
          }
        }
        ndcSamples: allFile(
          filter: {
            sourceInstanceName: { eq: "references/ndc-apis/samples" }
            relativePath: { ne: "index.mdx" }
            name: { eq: "index" }
          }
          sort: { order: ASC, fields: childrenMdx___frontmatter___order }
        ) {
          nodes {
            childMdx {
              frontmatter {
                title
                date
              }
              slug
            }
          }
        }
        airlines: allFile(
          filter: {
            sourceInstanceName: { eq: "airlines" }
            relativePath: { ne: "index.mdx" }
          }
          sort: { order: ASC, fields: childrenMdx___frontmatter___order }
        ) {
          nodes {
            childMdx {
              frontmatter {
                title
                date
              }
              slug
            }
          }
        }
      }
    `}
    render={data => {
      let path = useLocation().pathname.split("/")
      const search = useLocation().search
      const params = new URLSearchParams(search)
      const param = params.get("name")

      //   let apiData = [
      //     {
      //        "childMdx":{
      //           "frontmatter":{
      //              "title":"Query",
      //           },
      //           "slug":"detail/?name=Query/"
      //        }
      //     },
      //     {
      //        "childMdx":{
      //           "frontmatter":{
      //              "title":"Mutation",
      //           },
      //           "slug":"detail/?name=Mutation/"
      //        }
      //     }
      //  ];

      return (
        <div className={`navigations${isShowResponsive ? " show" : ""}`}>
          <div className="navigation-group">
            <div className="menu-heading-no-sub-menu">
              <Link to="/" activeClassName="active">
                Welcome
              </Link>
            </div>
          </div>

          <div className="navigation-group">
            <Link to="/concepts/" activeClassName="active">
              <CollapsibleSection title="Concepts" titleClass="menu-heading">
                <PageMenu
                  baseLink="/concepts/"
                  data={data?.concepts?.nodes}
                ></PageMenu>
              </CollapsibleSection>
            </Link>
          </div>
          <div className="navigation-group">
            <Link to="/airlines/">
              <CollapsibleSection title="Airlines" titleClass="menu-heading">
                <PageMenu
                  baseLink="/airlines/"
                  data={data?.airlines?.nodes}
                ></PageMenu>
              </CollapsibleSection>
            </Link>
          </div>

          <div className="navigation-group">
            <div className="menu-heading-no-sub-menu">
              <Link to="/api-explorer/" activeClassName="active">
                API Explorer
              </Link>
            </div>
          </div>

          <div className="navigation-group">
            <Link to="/references/">
              <CollapsibleSection title="References" titleClass="menu-heading">
                <div className="navigation-sub-group">
                  <Link to="/references/ndc-apis/">
                    <CollapsibleSection
                      title="NDC APIs"
                      titleClass="menu-sub-heading"
                    >
                      <div className="navigation-sub-sub-group">
                        <Link to="/references/ndc-apis/usages/">
                          <CollapsibleSection
                            title="Usages"
                            titleClass="menu-sub-sub-heading"
                          >
                            <PageMenu
                              baseLink="/references/ndc-apis/usages/"
                              data={data?.ndcUsages?.nodes}
                            ></PageMenu>
                          </CollapsibleSection>
                        </Link>

                        <Link to="/references/ndc-apis/samples/">
                          <CollapsibleSection
                            title="Samples"
                            titleClass="menu-sub-sub-heading"
                          >
                            <PageMenu
                              baseLink="/references/ndc-apis/samples/"
                              data={data?.ndcSamples?.nodes}
                            ></PageMenu>
                          </CollapsibleSection>
                        </Link>

                        <Link to="/references/ndc-apis/api/?type=ndc-api">
                          <CollapsibleSection
                            title="API"
                            titleClass="menu-sub-sub-heading"
                          >
                            {/* {param === 'Query' || param === 'Mutation' ? 'menu-sub-sub-heading active' : 'menu-sub-sub-heading'} */}
                            <div className="page-navigation">
                              <div>
                                <Link
                                  to="/references/ndc-apis/api/?type=ndc-api&name=Query"
                                  activeClassName="active"
                                  className={
                                    path[3] === "api" && param === "Query"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Query
                                </Link>
                              </div>
                            </div>
                            <div className="page-navigation">
                              <div>
                                <Link
                                  to="/references/ndc-apis/api/?type=ndc-api&name=Mutation"
                                  activeClassName="active"
                                  className={
                                    path[3] === "api" && param === "Mutation"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Mutation
                                </Link>
                              </div>
                            </div>
                          </CollapsibleSection>
                        </Link>
                      </div>
                    </CollapsibleSection>
                  </Link>
                </div>

                <div className="navigation-sub-group">
                  <Link to="/references/platform-apis/api/?type=platform-api">
                    <CollapsibleSection
                      title="Platform APIs"
                      titleClass="menu-sub-heading"
                    >
                      <div className="navigation-sub-sub-group">
                        <div className="page-navigation">
                          <div>
                            <Link
                              to="/references/platform-apis/api/?type=platform-api&name=Query"
                              activeClassName="active"
                              className={
                                path[3] === "api" && param === "Query"
                                  ? "active"
                                  : ""
                              }
                            >
                              Query
                            </Link>
                          </div>
                        </div>

                        <div className="page-navigation">
                          <div>
                            <Link
                              to="/references/platform-apis/api/?type=platform-api&name=Mutation"
                              activeClassName="active"
                              className={
                                path[3] === "api" && param === "Mutation"
                                  ? "active"
                                  : ""
                              }
                            >
                              Mutation
                            </Link>
                          </div>
                        </div>
                      </div>
                    </CollapsibleSection>
                  </Link>
                </div>
              </CollapsibleSection>
            </Link>
          </div>

          <div className="navigation-group">
            <Link to="/tutorials/">
              <CollapsibleSection title="Tutorials" titleClass="menu-heading">
                <PageMenu
                  data={data?.tutorials?.nodes}
                  baseLink="/tutorials/"
                ></PageMenu>
              </CollapsibleSection>
            </Link>
          </div>

          <div className="navigation-group">
            <Link to="/support/">
              <CollapsibleSection title="Support" titleClass="menu-heading">
                <PageMenu
                  data={[
                    {
                      childMdx: { frontmatter: { title: "FAQ" }, slug: "faq/" },
                    },
                    {
                      childMdx: {
                        frontmatter: { title: "Change Log" },
                        slug: "change-log/",
                      },
                    },
                  ]}
                  baseLink="/support/"
                ></PageMenu>
              </CollapsibleSection>
            </Link>
          </div>

          {/* <div className="navigation-group">
            <div className="menu-heading-no-sub-menu">
              <a href="https://nuflights.com">NuFlights Home</a>
            </div>
          </div> */}
        </div>
      )
    }}
  />
)

export default NavigationMenu
