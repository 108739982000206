import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

const PageMenu = ({ data, baseLink }) => {
  // const [path, setPath] = React.useState("")

  // React.useEffect(() => {
  let path = useLocation().pathname.split("/").pop()
  //   setPath(path)
  // }, [])

  // if (path) {

  return (
    <div className="page-navigation">
      {data?.map(
        (node, index) =>
          node?.childMdx && (
            <div key={index}>
              <Link
                to={baseLink + node?.childMdx?.slug}
                activeClassName="active"
                className={path === node?.childMdx?.slug ? "active" : ""}
              >
                {node?.childMdx?.frontmatter?.title}
              </Link>
            </div>
          )
      )}
    </div>
  )
  // } else {
  //   return null
  // }
}

export default PageMenu
